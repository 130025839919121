import getConfig from 'next/config';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

const nextConfig = getConfig();
const basePath = nextConfig?.publicRuntimeConfig?.basePath || process.env.BASE_PATH;

type ImageLoaderProps = {
  src: string;
  width?: number;
  quality?: number;
};

/**
 * Custom image loader for Contentful images.
 * @param {ImageLoaderProps} props - Image loader properties, including src, width, and quality.
 * @returns {string} - Formatted URL with updated width, and quality parameters.
 */
const contentfulImageLoader = ({ src, width, quality = 75 }: ImageLoaderProps): string => {
  const url = new URL(`${basePath}${getAssetApiPath(src)}`);
  if (width) {
    url.searchParams.set('w', width.toString());
  }

  url.searchParams.set('q', quality.toString());

  return url.toString();
};

export default contentfulImageLoader;
