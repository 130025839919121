import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Heading, Link, media, Text, textStyle } from '@elseu/sdu-titan-web-commerce';
import type {
  Asset as ContentfulAsset,
  ContentContent,
  IconList as ContentfulIconList,
  Image as ContentfulImage,
  Page as ContentfulPage,
  Person as ContentfulPerson,
  QuestionAnswer,
  ToastMessage,
  Video as ContentfulVideo,
} from 'generated/graphql';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { buildPageUrl } from 'utilities/buildPageUrl';
import { getImageSizes } from 'utilities/getImageSizes';
import { linkParser } from 'utilities/linkParser';

import { IconList } from '../IconList';
import { Image } from '../Image';
import { Person } from '../Person';
import { Video } from '../Video';

/**
 * This function creates maps for the different kind of assets and entries that can be linked in the rich text.
 *
 * @param {Object} links - An object containing the links for the rich text.
 * @param {Array<Object>} links.assets?.hyperlink - An array of linked assets with hyperlink relation.
 * @param {Array<Object>} links.entries?.block - An array of linked entries with block relation.
 * @param {Array<Object>} links.entries?.hyperlink - An array of linked entries with hyperlink relation.
 * @returns {Object} - An object containing the maps for different kind of assets and entries that can be linked in the rich text.
 * @returns {Map<string, ContentfulAsset>} - Map containing the linked assets with hyperlink relation.
 * @returns {Map<string, ContentfulPerson | ContentfulImage>} - Map containing the linked entries with block relation.
 * @returns {Map<string, ContentfulPage>} - Map containing the linked entries with hyperlink relation.
 */
const createAssetMaps = (
  links: RichTextProps['document']['links'],
): {
  assetMap: Map<string, ContentfulAsset>;
  entryMap: Map<string, ContentfulPerson | ContentfulImage>;
  hyperlinkMap: Map<string, ContentfulPage>;
} => {
  const assetMap = new Map<string, ContentfulAsset>();
  const entryMap = new Map<string, ContentfulPerson | ContentfulImage>();
  const hyperlinkMap = new Map<string, ContentfulPage>();

  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  links.assets?.hyperlink?.forEach((asset) => assetMap.set(asset.sys.id, asset));
  links.entries?.block?.forEach((entry) => entryMap.set(entry.sys.id, entry));
  links.entries?.hyperlink?.forEach((entry) => hyperlinkMap.set(entry.sys.id, entry));
  /* eslint-enable @typescript-eslint/no-unnecessary-condition */

  return { entryMap, hyperlinkMap, assetMap };
};

/**
 * This is a mapping of the GraphQL typename to the component for use inside Rich Text
 */
const entryComponentMapping = {
  Person,
  Image,
  Video,
  IconList,
};

const TableContainer = styled.table`
  ${({ theme }) => css`
    overflow-x: auto;
    display: block;
    width: 100%;

    ${media.greaterThan('s')`
      max-width: calc(768px - ${theme.spacing[8]});
    `}
  `}
`;

const Table = styled.tbody`
  min-width: 768px;
  display: block;
  width: 100%;
  border-collapse: collapse;
`;

const TableCell = styled.td`
  ${({ theme }) => css`
    vertical-align: top;
    padding: 0 ${theme.spacing[2]};
  `}
`;

const TableHeaderCell = styled.th`
  text-align: left;

  p {
    ${textStyle('paragraphBold')}
  }
`;

const StyledLink = styled(Link)`
  ${textStyle('paragraphLarge')}
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

/**
 * Processes a text node by replacing newline characters with <br/> elements.
 *
 * @param {string} text - The text to process.
 * @returns {JSX.Element[]} An array of React elements with newline characters replaced by <br/> elements.
 */
const processTextNode = (text: string): JSX.Element[] => {
  const lines = text.split('\n');

  return lines.map((line, index) => (
    /* eslint-disable-next-line react/no-array-index-key */
    <Fragment key={index}>
      {line}
      {index < lines.length - 1 && <br />}
    </Fragment>
  ));
};

/**
 * Returns the options object to be passed to documentToReactComponents
 * based on the links available in the RichTextProps's document.
 *
 * @param {RichTextProps['document']['links']} links - The links available in the RichTextProps's document
 * @returns {Options} The options object to be passed to documentToReactComponents
 */
const renderOptions = (links: RichTextProps['document']['links']): Options => {
  const { assetMap, entryMap, hyperlinkMap } = createAssetMaps(links);
  const options: Options = {
    renderText: (text) => (text ? processTextNode(text) : null),
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <Text as="strong" type="paragraphBold">
          {text}
        </Text>
      ),
      [MARKS.ITALIC]: (text) => (
        <Text as="em" type="paragraph">
          {text}
        </Text>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children = []) => {
        const childrenArray = React.Children.toArray(children);
        const hasNonEmptyChild = childrenArray.some((child) => child);

        return hasNonEmptyChild ? (
          <Text as="p" type="paragraph" isBlock>
            {children}
          </Text>
        ) : null;
      },
      [BLOCKS.TABLE]: (_node, children) => (
        <TableContainer>
          <Table>{children}</Table>
        </TableContainer>
      ),
      [BLOCKS.TABLE_ROW]: (_node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_node, children) => <TableCell>{children}</TableCell>,
      [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
        <TableHeaderCell>{children}</TableHeaderCell>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => <Heading heading="h2">{children}</Heading>,
      [BLOCKS.HEADING_3]: (_node, children) => <Heading heading="h3">{children}</Heading>,
      [BLOCKS.HEADING_4]: (_node, children) => <Heading heading="h4">{children}</Heading>,
      [BLOCKS.UL_LIST]: (_node, children) => (
        <Text as="ul" type="paragraph" isBlock>
          {children}
        </Text>
      ),
      [BLOCKS.OL_LIST]: (_node, children) => (
        // @ts-ignore - The type definition for the OL_LIST is wrong
        <Text as="ol" type="paragraph" isBlock>
          {children}
        </Text>
      ),
      [BLOCKS.LIST_ITEM]: (node) => {
        const transformedChildren = documentToReactComponents(node as any, {
          renderMark: options.renderMark,
          renderNode: {
            ...options.renderNode,
            [BLOCKS.PARAGRAPH]: (_node, children) => children,
            [BLOCKS.LIST_ITEM]: (_node, children) => children,
          },
        });

        return <li>{transformedChildren}</li>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
        const entry:
          | ContentfulPerson
          | ContentfulImage
          | ContentfulVideo
          | ContentfulIconList
          | undefined = entryMap.get(data.target.sys.id);

        if (!entry?.__typename) return null;

        const Component = entryComponentMapping[entry.__typename];

        const additionalProps = {
          ...(entry.__typename === 'Image' && {
            maxWidth: 800,
            sizes: getImageSizes({ xs: '100vw' }),
          }),
          ...(entry.__typename === 'Person' && {
            orientation: 'horizontal',
            hasWrapper: true,
          }),
        };

        // @ts-ignore - FIXME: The type definition for the EMBEDDED_ENTRY is wrong
        return <Component key={entry.sys.id} {...entry} {...additionalProps} />;
      },

      [INLINES.HYPERLINK]: ({ data }, children) => {
        if (!data.uri) return null;

        return <StyledLink to={data.uri}>{children}</StyledLink>;
      },
      [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => {
        const page = hyperlinkMap.get(data.target.sys.id);

        if (!page?.category || !page.slug) return null;

        const { category, slug } = page;
        const href = buildPageUrl({ category, slug });

        if (!href) return null;

        return <StyledLink to={href}>{children}</StyledLink>;
      },
      [INLINES.ASSET_HYPERLINK]: ({ data }, children) => {
        const asset = assetMap.get(data.target.sys.id);
        const url = linkParser({ asset });

        if (!url) return null;

        return (
          <StyledLink to={url} isExternal>
            {children}
          </StyledLink>
        );
      },
    },
  };

  return options;
};

type RichTextProps = { document: QuestionAnswer | ContentContent | ToastMessage };

/**
 * This component will render the rich text content from Contentful
 */
export const RichText: React.FC<RichTextProps> = ({ document }) => (
  <>{documentToReactComponents(document.json, renderOptions(document.links))}</>
);
