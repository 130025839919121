import { Breadcrumb, Footer as TitanFooter } from '@elseu/sdu-titan-web-commerce';
import { Icon } from 'components/Icon';
import type { Footer as ContentfulFooter } from 'generated/graphql';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { BreadcrumbList } from 'schema-dts';
import styled from 'styled-components';
import { linkParser } from 'utilities/linkParser';

type FooterProps = Pick<
  ContentfulFooter,
  'logosCollection' | 'bottomCollection' | 'topCollection' | 'socialsCollection' | 'copyright'
> & {
  /** Whether to display the breadcrumb. */
  hasBreadcrumb?: boolean | null;
};

/**
 * Capitalizes the first letter of the input string.
 *
 * @param {string} string - The input string to capitalize.
 * @returns {string} The string with the first letter capitalized.
 */
const capitalizeFirstLetter = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

/**
 * Replaces dashes with spaces in the input string.
 *
 * @param {string} string - The input string to replace dashes.
 * @returns {string} The string with dashes replaced by spaces.
 */
const replaceDashesWithSpaces = (string: string): string => string.replace(/-/g, ' ');

/**
 * Formats specified keywords in the input string.
 *
 * @param {string} string - The input string to format keywords in.
 * @returns {string} The string with the specified keywords formatted.
 */
const formatKeywords = (string: string): string => {
  const formattedKeywords = {
    ndfr: 'NDFR',
    opmaat: 'OpMaat',
    esg: 'ESG',
    hse: 'HSE',
    vind: 'VIND',
    hub: 'huB',
    or: 'OR',
  } as Record<string, string>;

  const words = string.split(' ');

  return words
    .map((word: string): string => {
      const formattedWord = formattedKeywords[word.toLowerCase()];
      return formattedWord || word;
    })
    .join(' ');
};

type IBreadcrumbItem = {
  label: string;
  to: string;
};

type IBreadcrumbItemWithoutTo = {
  label: string;
  to?: string;
};

type IBreadcrumbItems = [...IBreadcrumbItem[], IBreadcrumbItemWithoutTo] | IBreadcrumbItem[] | null;

/**
 * Builds a breadcrumb navigation based on the given path.
 *
 * @param {string} path - The path used to build the breadcrumb.
 * @returns {IBreadcrumbItems} - An array of breadcrumb items, or null if the path is invalid.
 */
const buildTitanBreadcrumb = (path: string): IBreadcrumbItems => {
  const pathWithoutHash = path.split('#')[0];

  if (pathWithoutHash === '/') {
    return null;
  }

  const parts = pathWithoutHash.split('/');

  if (parts.length === 0) {
    return null;
  }

  return parts.map((item, index, items) => {
    const label = item
      ? formatKeywords(capitalizeFirstLetter(replaceDashesWithSpaces(item)))
      : 'Home';
    const to = items.slice(0, index + 1).join('/') || '/';
    const isLast = index === items.length - 1;

    return isLast ? ({ label } as IBreadcrumbItemWithoutTo) : ({ to, label } as IBreadcrumbItem);
  }) as [...IBreadcrumbItem[], IBreadcrumbItemWithoutTo] | IBreadcrumbItem[];
};

/**
 * Generates the JSON-LD for a breadcrumb using the schema.org BreadcrumbList type.
 *
 * @param {IBreadcrumbItems} breadcrumb - An array of breadcrumb items, or null if not applicable.
 * @returns {BreadcrumbList | null} - The generated JSON-LD for the breadcrumb, or null if no breadcrumb is provided.
 */
const generateBreadcrumbJsonLd = (breadcrumb: IBreadcrumbItems): BreadcrumbList | null => {
  if (!breadcrumb) {
    return null;
  }

  const itemListElement = breadcrumb
    .map(({ to, label }, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': to,
          name: label,
        },
      };
    })
    .filter(({ item }) => item['@id'] && item.name);

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  } as BreadcrumbList;
};

const StyledTitanFooter = styled(TitanFooter)`
  ${({ theme }) => `
    [data-test-id="logoELS"] {
      svg {
        background-color: ${theme.designTokens.colors.grey0};
      }
    }
  `}
`;

export const Footer: React.FC<FooterProps> = ({
  bottomCollection,
  topCollection,
  socialsCollection,
  hasBreadcrumb,
}) => {
  const withBreadcrumb = hasBreadcrumb || hasBreadcrumb === null;
  const { asPath } = useRouter();

  const titanBreadcrumbs = withBreadcrumb ? buildTitanBreadcrumb(asPath.split('?')[0]) : null;
  const breadcrumbJsonLd = withBreadcrumb ? generateBreadcrumbJsonLd(titanBreadcrumbs) : null;

  const middle = topCollection?.items.map((link) => ({
    label: link.label!,
    to: linkParser(link),
  }));
  const bottom = bottomCollection?.items.map((link) => ({
    label: link.label!,
    to: linkParser(link),
  }));
  const socials = socialsCollection?.items.map(({ icon, text, link }) => ({
    href: link!,
    label: text!,
    icon: icon ? <Icon icon={icon} /> : undefined,
  }));

  return (
    <>
      {breadcrumbJsonLd && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJsonLd) }}
          />
        </Head>
      )}
      <StyledTitanFooter
        px={{
          xs: 4,
          s: 8,
          m: 12,
          l: 24,
        }}
        maxContentWidth={1400}
        links={{
          middle,
          bottom,
          socials,
        }}
        {...(titanBreadcrumbs ? { breadcrumb: <Breadcrumb items={titanBreadcrumbs} /> } : {})}
      />
    </>
  );
};
