import { Loader, Navigation as DSNavigation, useGreaterThan } from '@elseu/sdu-titan-web-commerce';
import { captureException } from '@sentry/nextjs';
import { Image } from 'components/Image';
import type {
  Link as ContentfulLink,
  Navigation as ContentfulNavigation,
  NavigationGroup,
  NavigationMainItem,
} from 'generated/graphql';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { linkParser } from 'utilities/linkParser';

type NavigationProps = Pick<ContentfulNavigation, 'mainCollection' | 'topCollection' | 'logo'>;

/**
 * Type handlers for NavigationGroup and Link items
 */
type TypeHandlers = {
  NavigationGroup: (item: NavigationGroup) => {
    id: string;
    title: string;
    to: string;
    children: Array<{
      title: string;
      children: Array<{
        id: string;
        title: string;
        to: string;
      }>;
    }>;
  };
  Link: (item: ContentfulLink) => { id: string; title: string; to: string };
};

const DynamicSearch = dynamic(
  () =>
    import(/* webpackChunkName: "Search" */ '@elseu/sdu-titan-web-commerce').then(
      (module) => module.Search,
    ),
  {
    ssr: false,
    loading: () => <Loader variant="spinner" height={44} />,
  },
);

/**
 * Type handlers for NavigationGroup and Link items
 */
const typeHandlers: TypeHandlers = {
  NavigationGroup: (item: NavigationGroup) => ({
    id: item.link?.label || '',
    title: item.link?.label || '',
    to: linkParser({ ...item.link }) || '',
    children:
      item.columnsCollection?.items.map(({ linksCollection, title }) => ({
        title: title || '',
        children:
          linksCollection?.items.map((link) => ({
            id: link.label || '',
            title: link.label || '',
            to: linkParser({ ...link }) || '',
          })) || [],
      })) || [],
  }),
  Link: ({ label, ...link }) => ({
    id: label || '',
    title: label || '',
    to: linkParser({ ...link }) || '',
  }),
};

/**
 * Determines if the given item is of type Link
 * @param item - The item to check
 * @returns true if the item is a Link, false otherwise
 */
const isLink = (item: NavigationMainItem): item is ContentfulLink => item.__typename === 'Link';

/**
 * Processes a NavigationMainItem and returns the appropriate type handler result
 * @param item - The item to process
 * @returns The result of the type handler or undefined
 */
const processItem = (item: NavigationMainItem) =>
  isLink(item) ? typeHandlers.Link(item) : typeHandlers.NavigationGroup(item);

/**
 * Navigation component that takes mainCollection and topCollection as props
 */
export const Navigation: React.FC<NavigationProps> = ({ mainCollection, topCollection, logo }) => {
  const [shouldCloseMenu, setShouldCloseMenu] = useState(false);
  const main = mainCollection?.items.map(processItem);
  const top = topCollection?.items.map(({ label, ...link }) => ({
    id: label || '',
    title: label || '',
    to: linkParser({ ...link }) || '',
  }));
  const router = useRouter();
  const isLarge = useGreaterThan('s');

  /**
   * Route to the given url.
   * If the url starts with /shop/ then we need to do a full page reload.
   * @param url - The url to route to
   **/
  const route = (url: string) => {
    if (url.startsWith('/shop/')) {
      window.location.assign(url);
    } else {
      router.push(url);
    }
  };

  /**
   * Close the menu columns when the route changes.
   */
  useEffect(() => {
    const closeMenu = () => {
      setShouldCloseMenu(true);
    };

    router.events.on('routeChangeComplete', closeMenu);

    /* Clean up the event listener when the component unmounts */
    return () => {
      router.events.off('routeChangeComplete', closeMenu);
    };
  }, [router.events]);

  /**
   * Reset the shouldCloseMenu state when the shouldCloseMenu prop changes.
   */
  useEffect(() => {
    if (shouldCloseMenu) {
      setShouldCloseMenu(false);
    }
  }, [shouldCloseMenu]);

  return (
    <DSNavigation
      main={main}
      top={top}
      logo={logo ? <Image width={44} {...logo} unoptimized /> : null}
      shouldCloseMenu={shouldCloseMenu}
      search={
        <DynamicSearch
          onSubmit={(url) => route(url)}
          onSelect={(url) => route(url)}
          onError={(error) => captureException(error)}
          onCancel={() => setShouldCloseMenu(true)}
          isMobile={!isLarge}
        />
      }
    />
  );
};
