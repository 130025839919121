import { Toast as DSToast } from '@elseu/sdu-titan-web-commerce';
import { RichText } from 'components/RichText';
import type { Toast as ContentfulToast } from 'generated/graphql';

type ToastProps = ContentfulToast;

export const Toast: React.FC<ToastProps> = ({ message, internalName }) => {
  if (!message) return null;

  return (
    <DSToast name={internalName}>
      <RichText document={message} />
    </DSToast>
  );
};
