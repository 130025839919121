/**
 * Builds a page URL based on the given slug and category.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | undefined} params.slug - The slug for the URL.
 * @param {string | undefined} params.category - The category for the URL.
 * @returns {string} - The constructed URL.
 */
export const buildPageUrl = ({
  slug = '',
  category = '',
}: {
  slug?: string;
  category?: string;
}): string => {
  if (slug === 'root') {
    return '/';
  }

  if (category === '/') {
    return `/${slug}`;
  }

  return `${category}/${slug}`;
};
