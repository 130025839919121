import type { Seo as ContentfulSeo } from 'generated/graphql';
import getConfig from 'next/config';
import Head from 'next/head';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

const nextConfig = getConfig();
const basePath = nextConfig?.publicRuntimeConfig?.basePath || process.env.BASE_PATH;

/**
 * Type definition for Seo component props.
 * Extends the properties from the ContentfulSeo type and adds a 'url' property.
 */
type SeoProps = Pick<
  ContentfulSeo,
  | 'canonical'
  | 'follow'
  | 'index'
  | 'metaDescription'
  | 'pageTitle'
  | 'socialShareDescription'
  | 'socialShareImage'
  | 'socialShareTitle'
> & {
  url: string;
};

/**
 * The Seo component renders the appropriate meta tags, open graph, and Twitter cards for SEO purposes.
 * It handles canonical URLs, indexing and following settings, and social sharing images and descriptions.
 *
 * @param {SeoProps} props - The properties for the Seo component.
 * @returns {JSX.Element} The rendered Seo component.
 */
export const Seo: React.FC<SeoProps> = ({
  canonical,
  follow,
  index,
  metaDescription,
  pageTitle,
  socialShareDescription,
  socialShareImage,
  socialShareTitle,
  url,
}) => {
  const completeUrl = `${basePath}${url}`;
  const completePageTitle = url === '/' ? pageTitle : `${pageTitle} | Sdu`;
  const socialShareImageUrl = socialShareImage?.url ? getAssetApiPath(socialShareImage.url) : null;

  return (
    <Head>
      {/* Title & Description */}
      <title>{completePageTitle}</title>
      <meta key="title" content={completePageTitle} name="title" />
      <meta key="description" content={metaDescription} name="description" />
      <link key="canonical" href={canonical ?? completeUrl} rel="canonical" />

      {/* Open Graph */}
      <meta key="og:type" content="website" property="og:type" />
      <meta key="og:url" content={completeUrl} property="og:url" />
      <meta key="og:title" content={socialShareTitle || completePageTitle} property="og:title" />
      <meta
        key="og:description"
        content={socialShareDescription || metaDescription}
        property="og:description"
      />
      <meta key="og:locale" content="nl_NL" property="og:locale" />
      {socialShareImageUrl && (
        <meta key="og:image" content={socialShareImageUrl} property="og:image" />
      )}

      {/* Twitter */}
      <meta key="twitter:card" content="summary_large_image" property="twitter:card" />
      <meta key="twitter:url" content={completeUrl} property="twitter:url" />
      <meta
        key="twitter:title"
        content={socialShareTitle || completePageTitle}
        property="twitter:title"
      />
      <meta
        key="twitter:description"
        content={socialShareDescription || metaDescription}
        property="twitter:description"
      />
      {socialShareImageUrl && (
        <meta key="twitter:image" content={socialShareImageUrl} property="twitter:image" />
      )}

      {/* Robots */}
      {!index && !follow && <meta content="noindex, nofollow" name="robots" />}
      {!index && follow && <meta content="noindex, follow" name="robots" />}
      {index && !follow && <meta content="index, nofollow" name="robots" />}
      {index && follow && <meta content="index, follow" name="robots" />}

      {/* Theming */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ee3224" />
      <meta name="msapplication-TileColor" content="#f6f3ea" />
      <meta name="theme-color" content="#f6f3ea" />
    </Head>
  );
};
