import type { Link } from 'generated/graphql';
import { buildPageUrl } from 'utilities/buildPageUrl';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

/**
 * Parses a Contentful link object and returns the correct URL or page to link to.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object | undefined} params.contentfulPage - The Contentful page object.
 * @param {string | undefined} params.url - The URL to link to.
 * @returns {string} - The generated URL or page to link to.
 */
export const linkParser = ({
  contentfulPage,
  url,
  asset,
  anchorLink,
}: Pick<Link, 'contentfulPage' | 'url' | 'asset' | 'anchorLink'> & {
  currentPath?: string;
}): string => {
  if (contentfulPage) {
    return buildPageUrl(contentfulPage);
  }

  if (asset?.url) {
    return getAssetApiPath(asset.url);
  }

  if (anchorLink) {
    const splitAnchorLink = anchorLink.split('#');
    const section = splitAnchorLink[splitAnchorLink.length - 1];

    return `#${section}`;
  }

  return url ?? '';
};
