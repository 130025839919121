import { IconList as DSIconList } from '@elseu/sdu-titan-web-commerce';
import { Icon } from 'components/Icon';
import type { IconList as ContentfulIconList } from 'generated/graphql';
import React from 'react';

type IconListProps = Pick<ContentfulIconList, 'itemsCollection'>;

type IconListItems = Array<{
  /** The text to display next to the icon. */
  text: string;
  /** The icon to display. */
  icon: React.ReactNode;
}>;

/**
 * Renders a list of icons with accompanying text.
 *
 * @param {Object} props - The component props.
 * @param {ContentfulIconList} props.itemsCollection - The collection of icon items with text and icon data.
 * @returns {React.ReactNode} The rendered IconList component.
 */
export const IconList: React.FC<IconListProps & { iconColor?: string }> = ({
  itemsCollection,
  iconColor,
}) => {
  if (!itemsCollection?.items.length) return null;

  const items = itemsCollection.items
    .map(
      ({ text, icon }) =>
        icon &&
        text && {
          text,
          icon: <Icon icon={icon} />,
        },
    )
    .filter(Boolean);

  return <DSIconList items={items as IconListItems} iconColor={iconColor} />;
};
