import { Person as DSPerson } from '@elseu/sdu-titan-web-commerce';
import type { Person as ContentfulPerson } from 'generated/graphql';
import React from 'react';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

/**
 * PersonProps is a type representing a subset of ContentfulPerson properties
 * that are used to create the custom Person component.
 */
type PersonProps = Pick<
  ContentfulPerson,
  'emailAddress' | 'image' | 'name' | 'subtitle' | 'telephoneNumber'
> & {
  /** The orientation of the person. */
  orientation: 'horizontal' | 'vertical';
  /** Whether the person should be wrapped in a div. */
  hasWrapper?: boolean;
};

/**
 * Person is a custom wrapper component around DSPerson that takes in a
 * set of ContentfulPerson properties, gets the asset API path, and spreads
 * the properties onto the DSPerson component.
 *
 * @param {PersonProps} props - The properties for the custom Person component.
 * @returns {JSX.Element} The rendered custom Person component.
 */
export const Person: React.FC<PersonProps> = ({
  name,
  image,
  emailAddress,
  subtitle,
  telephoneNumber,
  orientation,
  hasWrapper,
}) => {
  if (!name || !image || !image.asset?.url) return null;

  return (
    <DSPerson
      name={name}
      image={{
        src: getAssetApiPath(image.asset.url),
        labelAlt: image.alt!,
      }}
      emailAddress={emailAddress}
      subtitle={subtitle}
      telephoneNumber={telephoneNumber}
      orientation={orientation}
      hasWrapper={hasWrapper}
    />
  );
};
