import dynamic from 'next/dynamic';
import type { SVGProps } from 'react';
import React, { useEffect, useState } from 'react';

/**
 * A record of icon names and their corresponding component names.
 */
const iconMapping: Record<string, string> = {
  'arrow-down': 'ArrowDownIcon',
  'arrow-left-bottom': 'ArrowLeftBottomIcon',
  'arrow-left-top': 'ArrowLeftTopIcon',
  'arrow-left': 'ArrowLeftIcon',
  'arrow-right-bottom': 'ArrowRightBottomIcon',
  'arrow-right-top': 'ArrowRightTopIcon',
  'arrow-right': 'ArrowRightIcon',
  'bell-filled': 'BellFilledIcon',
  'bell-notification': 'BellNotificationIcon',
  'bell-outline': 'BellOutlineIcon',
  'book-opened': 'BookOpenedIcon',
  'bookmark-add-filled': 'BookmarkAddFilledIcon',
  'bookmark-add-outline': 'BookmarkAddOutlineIcon',
  'box-alt': 'BoxAltIcon',
  'chat-alt': 'ChatAltIcon',
  'chevron-double-left': 'ChevronDoubleLeftIcon',
  'chevron-double-right': 'ChevronDoubleRightIcon',
  'chevron-down': 'ChevronDownIcon',
  'chevron-left': 'ChevronLeftIcon',
  'chevron-right': 'ChevronRightIcon',
  'chevron-up': 'ChevronUpIcon',
  'controls-vertical-alt': 'ControlsVerticalAltIcon',
  'download-pdf': 'DownloadPdfIcon',
  'drawer-open': 'DrawerOpenIcon',
  'ellypsis-vertical': 'EllypsisVerticalIcon',
  'external-link': 'ExternalLinkIcon',
  'eye-crossed': 'EyeCrossedIcon',
  'fit screen': 'FitscreenIcon',
  'folder-add': 'FolderAddIcon',
  'light bulb': 'LightbulbIcon',
  'list-search': 'ListSearchIcon',
  'lock-alt-open': 'LockAltOpenIcon',
  'lock-alt': 'LockAltIcon',
  'lock-open': 'LockOpenIcon',
  'menu-collapsable-2': 'MenuCollapsable2Icon',
  'menu-collapsable': 'MenuCollapsableIcon',
  'menu-dots': 'MenuDotsIcon',
  'ok-circle': 'OkCircleIcon',
  'people-2': 'People2Icon',
  'play-filled': 'PlayFilledIcon',
  'social-x': 'SocialXIcon',
  'star-filled': 'StarFilledIcon',
  'star-outline': 'StarOutlineIcon',
  'swap-horizontal': 'SwapHorizontalIcon',
  'thumb-down': 'ThumbDownIcon',
  'thumb-up': 'ThumbUpIcon',
  bag: 'BagIcon',
  basket: 'BasketIcon',
  binoculars: 'BinocularsIcon',
  bolt: 'BoltIcon',
  book: 'BookIcon',
  bookmark: 'BookmarkIcon',
  business: 'BusinessIcon',
  calendar: 'CalendarIcon',
  cancel: 'CancelIcon',
  cards: 'CardsIcon',
  chat: 'ChatIcon',
  checkbox: 'CheckboxIcon',
  clipboard: 'ClipboardIcon',
  close: 'CloseIcon',
  collapse: 'CollapseIcon',
  controls: 'ControlsIcon',
  copy: 'CopyIcon',
  cut: 'CutIcon',
  danger: 'DangerIcon',
  download: 'DownloadIcon',
  ellipsis: 'EllipsisIcon',
  envelope: 'EnvelopeIcon',
  error: 'ErrorIcon',
  exit: 'ExitIcon',
  expand: 'ExpandIcon',
  eye: 'EyeIcon',
  facebook: 'FacebookIcon',
  favourite: 'FavouriteIcon',
  file: 'FileIcon',
  film: 'FilmIcon',
  fitscreen: 'FitscreenIcon',
  folder: 'FolderIcon',
  future: 'FutureIcon',
  grid: 'GridIcon',
  help: 'HelpIcon',
  home: 'HomeIcon',
  information: 'InformationIcon',
  instagram: 'InstagramIcon',
  library: 'LibraryIcon',
  lightbulb: 'LightbulbIcon',
  linkedin: 'LinkedinIcon',
  list: 'ListIcon',
  lock: 'LockIcon',
  magazine: 'MagazineIcon',
  menu: 'MenuIcon',
  minus: 'MinusIcon',
  news: 'NewsIcon',
  notification: 'BellNotificationIcon',
  ntfr: 'NtfrIcon',
  ok: 'OkIcon',
  pencil: 'PencilIcon',
  people: 'PeopleIcon',
  person: 'PersonIcon',
  play: 'PlayIcon',
  plus: 'PlusIcon',
  print: 'PrintIcon',
  radio: 'RadioIcon',
  rotate: 'RotateIcon',
  search: 'SearchIcon',
  settings: 'SettingsIcon',
  share: 'ShareIcon',
  shield: 'ShieldIcon',
  tasklist: 'TasklistIcon',
  time: 'TimeIcon',
  timer: 'TimerIcon',
  upgrade: 'UpgradeIcon',
  upload: 'UploadIcon',
  user: 'UserIcon',
  video: 'VideoIcon',
  world: 'WorldIcon',
  xposi: 'XposiIcon',
  youtube: 'YoutubeIcon',
};

type IconName = keyof typeof iconMapping;

type IconProps = {
  icon: IconName;
};

/**
 * A dynamic icon component that dynamically imports and renders an icon.
 *
 * @param {Object} props - The props object.
 * @param {IconName} props.icon - The name of the icon to render.
 * @returns {JSX.Element|null} The dynamically loaded icon component or null.
 */
const DynamicIcon: React.FC<IconProps> = ({ icon }) => {
  const [IconComponent, setIconComponent] = useState<React.ComponentType<
    SVGProps<SVGSVGElement>
  > | null>(null);

  useEffect(() => {
    const loadIcon = async () => {
      if (icon in iconMapping) {
        const loadedIcon = await dynamic(
          () =>
            import(/* webpackChunkName: "Icon" */ '@elseu/sdu-titan-web-commerce').then(
              (module) => module[iconMapping[icon]],
            ) as unknown as Promise<React.ComponentType<SVGProps<SVGSVGElement>>>,
        );
        setIconComponent(() => loadedIcon);
      }
    };
    loadIcon();
  }, [icon]);

  return IconComponent ? <IconComponent role="img" /> : null;
};

/**
 * A versatile Icon component that renders icons dynamically based on the provided icon name.
 *
 * @example
 * ```
 * <Icon icon="arrow-down" />
 * ```
 *
 * @param {Object} props - The props object.
 * @param {IconName} props.icon - The name of the icon to render.
 * @returns {JSX.Element|null} The dynamically loaded icon component or null.
 */
export const Icon: React.FC<IconProps> = ({ icon }) => <DynamicIcon icon={icon} />;
