import { Page as DSPage } from '@elseu/sdu-titan-web-commerce';
import { Seo } from 'components/Seo';
import { Toast } from 'components/Toast';
import type {
  Page as ContentfulPage,
  Seo as ContentfulSeo,
  Toast as ContentfulToast,
} from 'generated/graphql';
import Head from 'next/head';
import type { WebSite } from 'schema-dts';
import { buildPageUrl } from 'utilities/buildPageUrl';

/**
 * PageProps is a type representing a subset of ContentfulPage properties
 * that are used to create the custom Page component.
 */
type PageProps = Pick<ContentfulPage, 'slug' | 'category'> & {
  /** The SEO properties for the page. */
  seo?: Pick<
    ContentfulSeo,
    | 'canonical'
    | 'follow'
    | 'index'
    | 'metaDescription'
    | 'pageTitle'
    | 'socialShareDescription'
    | 'socialShareImage'
    | 'socialShareTitle'
  >;
  /** The sections to display. */
  sections: React.ReactNode;
  /** The navigation to display. */
  navigation: React.ReactNode;
  /** The footer to display. */
  footer: React.ReactNode;
  /** The toast to display. */
  toast?: ContentfulToast | null;
  /** Whether the page is being previewed. */
  isPreview?: boolean;
};

/**
 * Page is a custom wrapper component around DSPage that takes in a
 * set of ContentfulPage properties, builds the page URL, and spreads
 * the properties onto the DSPage component.
 *
 * @param {PageProps} props - The properties for the custom Page component.
 * @returns {JSX.Element} The rendered custom Page component.
 */
export const Page: React.FC<PageProps> = ({
  seo,
  slug,
  category,
  sections,
  navigation,
  footer,
  toast,
  isPreview,
}) => {
  const url = buildPageUrl({ slug, category });
  const websiteJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Sdu',
    url: 'https://www.sdu.nl/',
  } as WebSite;

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteJsonLd) }}
        />
      </Head>
      <DSPage
        seo={seo && <Seo {...seo} url={url} />}
        url={url}
        sections={sections}
        navigation={navigation}
        footer={footer}
        isPreview={isPreview}
        toast={toast && <Toast {...toast} />}
      />
    </>
  );
};
