import { Video as DSVideo } from '@elseu/sdu-titan-web-commerce';
import type { Video as ContentfulVideo } from 'generated/graphql';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

type VideoProps = Pick<ContentfulVideo, 'youtubeId' | 'media'>;

/**
 * Extracts the Youtube ID from the given URL or ID string.
 * If it's not a valid URL, it just returns the input string.
 *
 * @param urlOrId - The URL or ID string to extract the Youtube ID from
 * @returns The extracted Youtube ID or the input string if it wasn't a valid URL
 */
const extractYoutubeId = (urlOrId: string): string => {
  try {
    const url = new URL(urlOrId);
    if (url.hostname.includes('youtube.com')) {
      const searchParams = new URLSearchParams(url.search);
      return searchParams.get('v') || urlOrId;
    }

    if (url.hostname.includes('youtu.be')) {
      return url.pathname.split('/').pop() || urlOrId;
    }
  } catch (error) {}

  return urlOrId;
};

/**
 * Renders a video component.
 *
 * @param youtubeId - The Youtube ID of the video to display
 * @param media - The media object containing information about the video
 */
export const Video: React.FC<VideoProps> = ({ youtubeId, media }) => {
  /* Proxy the media URL to the API server */
  const proxiedMedia = media?.url
    ? {
        ...media,
        url: getAssetApiPath(media.url),
      }
    : undefined;
  /* Extract the Youtube ID from the given string */
  const extractedYoutubeId = youtubeId && extractYoutubeId(youtubeId);

  return <DSVideo youtubeId={extractedYoutubeId} media={proxiedMedia} />;
};
