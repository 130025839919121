import type { Image as ContentfulImage } from 'generated/graphql';
import NextImage from 'next/image';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

type ImageProps = Pick<ContentfulImage, 'alt' | 'asset'> & {
  /** The requested height of the image. */
  height?: number;
  /** The requested width of the image. */
  width?: number;
  /** The maximum allowed width of the image. */
  maxWidth?: number;
  /** Whether the image should be loaded with priority. */
  isPriority?: boolean;
  /** The sizes attribute for the image. */
  sizes?: string;
  /** Whether the image should be loaded unoptimized. */
  // eslint-disable-next-line react/boolean-prop-naming
  unoptimized?: boolean;
};

/**
 * Calculate the correct width and height based on the requested dimensions, maximum width, and the asset's dimensions.
 * The function adjusts the dimensions according to the aspect ratio to maintain the original proportions of the asset.
 *
 * @param {Object} params - The parameters for the function.
 * @param {number | undefined} params.width - The requested width.
 * @param {number | undefined} params.height - The requested height.
 * @param {number} params.maxWidth - The maximum allowed width.
 * @param {Object} params.asset - The asset object containing its dimensions.
 * @param {number} params.asset.width - The asset's original width.
 * @param {number} params.asset.height - The asset's original height.
 * @returns {{ width: number, height: number }} - The calculated width and height based on the given parameters.
 */
const getCorrectWidthAndHeight = ({
  width: requestedWidth,
  height: requestedHeight,
  maxWidth,
  asset,
}: {
  width: number | undefined;
  height: number | undefined;
  maxWidth: number;
  asset: {
    width: number;
    height: number;
  };
}) => {
  /* Calculate the aspect ratio based on the asset's width and height */
  const aspectRatio = asset.width / asset.height;

  /** Set the initial width and height based on the requested values or the asset's values */
  let width = requestedWidth || asset.width;
  let height = requestedHeight || asset.height;

  if (requestedWidth && !requestedHeight) {
    /* If only width is requested, adjust the height according to the aspect ratio */
    height = Math.round(requestedWidth / aspectRatio);
  } else if (!requestedWidth && requestedHeight) {
    /* If only height is requested, adjust the width according to the aspect ratio */
    width = Math.round(requestedHeight * aspectRatio);
  }

  /* If the calculated width is less than or equal to the maximum width, return the width and height */
  if (width <= maxWidth) {
    return { width, height };
  }

  /* If the calculated width is greater than the maximum width, adjust the width and height accordingly */
  return { width: maxWidth, height: Math.round(maxWidth / aspectRatio) };
};

/**
 * Image component that takes a Contentful Image and renders it using Next.js Image component.
 * The component calculates the correct width and height based on the requested dimensions,
 * maximum width, and the asset's dimensions, maintaining the original proportions.
 *
 * @param {Object} props - The properties of the Image component.
 * @param {string} props.alt - The alt text of the image.
 * @param {Object} props.asset - The asset object containing its dimensions and URL.
 * @param {number} props.asset.width - The asset's original width.
 * @param {number} props.asset.height - The asset's original height.
 * @param {string} props.asset.url - The asset's URL.
 * @param {number | undefined} props.height - The requested height.
 * @param {number | undefined} props.width - The requested width.
 * @param {number | undefined} props.maxWidth - The maximum allowed width.
 * @param {boolean | undefined} props.isPriority - Whether the image should be loaded with priority.
 * @param {string | undefined} props.sizes - The sizes attribute for the image.
 * @param {boolean | undefined} props.unoptimized - Whether the image should be loaded unoptimized.
 * @returns {React.ReactElement | null} The rendered Image component or null if the required properties are missing.
 */
export const Image: React.FC<ImageProps> = ({
  alt,
  asset,
  height: requestedHeight,
  width: requestedWidth,
  maxWidth,
  isPriority,
  sizes,
  unoptimized,
}) => {
  if (!alt || !asset || !asset.url || Number.isNaN(asset.height) || Number.isNaN(asset.width)) {
    return null;
  }
  const { width, height } = getCorrectWidthAndHeight({
    width: requestedWidth,
    height: requestedHeight,
    asset: {
      width: asset.width!,
      height: asset.height!,
    },
    maxWidth: maxWidth || 1440,
  });

  return (
    <NextImage
      alt={alt}
      src={getAssetApiPath(asset.url)}
      height={height}
      width={width}
      priority={isPriority}
      sizes={sizes}
      unoptimized={unoptimized}
    />
  );
};
