import { breakpoints } from '@elseu/sdu-titan-web-commerce';

type Breakpoints = typeof breakpoints;

type Sizes = {
  [key in keyof Breakpoints]?: string;
};

/**
 * getImageSizes generates a sizes string for the `sizes` prop of an img element.
 * @param sizesObj - Object containing keys (xs, s, m, l, xl) and their respective size values.
 * @returns A formatted sizes string.
 */
export const getImageSizes = (sizesObj: Sizes): string =>
  Object.entries(sizesObj)
    .sort(
      ([keyA], [keyB]) =>
        breakpoints[keyA as keyof typeof breakpoints] -
        breakpoints[keyB as keyof typeof breakpoints],
    )
    .map(([key, value]) => {
      if (key === 'xs') return `${value}`;
      return `(min-width: ${breakpoints[key as keyof typeof breakpoints]}px) ${value}`;
    })
    .join(', ');
