import getConfig from 'next/config';

const nextConfig = getConfig();
const contentfulSpaceId =
  nextConfig?.publicRuntimeConfig?.contentfulSpaceId || process.env.CONTENTFUL_SPACE_ID;

/**
 * Generates an asset API path for the given asset URL.
 *
 * @param {string} assetUrl - The asset URL.
 * @returns {string} - The generated asset API path.
 */
export const getAssetApiPath = (assetUrl: string): string => {
  if (assetUrl.startsWith('/')) {
    return assetUrl;
  }

  const basePaths = [
    `https://videos.ctfassets.net/${contentfulSpaceId}/`,
    `https://assets.ctfassets.net/${contentfulSpaceId}/`,
    `https://images.ctfassets.net/${contentfulSpaceId}/`,
  ];

  const isValidAssetUrl = basePaths.some((basePath) => assetUrl.startsWith(basePath));

  if (!isValidAssetUrl) {
    throw new Error(`Invalid asset URL, ${assetUrl}`);
  }

  const [urlWithoutQuery] = assetUrl.split('?');
  const [fileType] = urlWithoutQuery.split('.').reverse();

  if (!fileType) {
    throw new Error(`Invalid file type ${fileType}`);
  }

  /** Find the basePath that the assetUrl starts with */
  const basePath = basePaths.find((basePath) => assetUrl.startsWith(basePath));

  /** Separate the URL path and the query parameters */
  const [urlPath, queryString] = assetUrl.split('?');

  const pathWithoutExtension = urlPath.substring(
    (basePath as string).length,
    urlPath.length - fileType.length - 1,
  );

  return `/api/${pathWithoutExtension}?filetype=${fileType}${queryString ? `&${queryString}` : ''}`;
};
